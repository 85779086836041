<template>
  <div class="h-full">
    <p
      v-if="fetchData.viewLoadError"
      class="ma-8 pa-6 rounded bg-white"
    >
      {{ fetchData.viewLoadError }}
    </p>

    <TheLandscape
      v-else
      ref="landscape"
      :view="view"
      :fields="fetchData.columns"
      :can-edit="canEdit"
      :can-create="canCreate"
      :can-delete="canDelete"
      :search="search"
      :loading="pending"
      :skeleton="pending"
      :current-group-by-field="recordToolboxOptions.hasGroup ? recordToolboxOptions.currentGroupByField : null"
      class="h-full"
      comfort-read-mode
      page-state-component-key="index"
    />
  </div>
</template>

<script lang="ts">
import viewChannelMixin from '~/mixins/viewChannelMixin';
import { mapState, mapGetters } from '~/assets/javascript/modules/vuex';
import ROUTES, { isChangingBetweenRoutes } from '~/assets/javascript/constants/routes';

export default defineComponent({
  name: 'MemberViews',
  components: {
    TheLandscape: defineAsyncComponent(() => import('~/components/views/the-landscape')),
  },
  mixins: [
    viewChannelMixin(),
  ],
  beforeRouteLeave(to, from) {
    if (!['Kanban', 'Matrix'].includes(this.view?.page_type)) return true;

    // Prevent change route even if user clicks on a record
    // Kanban change route virtually when open/close dialog
    const preventNavigation = isChangingBetweenRoutes({
      from: from.name,
      to: to.name,
      routes: [
        ROUTES.member.view.routeName,
        ROUTES.member.view.record.routeName,
      ],
    }) && to.params.viewId === this.$route.params.viewId;

    return !preventNavigation;
  },
  async setup() {
    definePageMeta({
      layout: 'member',
      middleware: ['enforce-app-dependencies'],
    });

    const vuex = {
      ...mapState('view', ['view']),
      ...mapGetters('view', ['canEdit', 'canCreate', 'canDelete']),
      ...mapGetters('recordList', ['recordToolboxOptions']),
      // eslint-disable-next-line vue/no-unused-properties
      ...mapGetters('records', ['hasChangesInDraftRecords']),
    };

    useConfirmLeave({
      warnRouteLeave() {
        return vuex.hasChangesInDraftRecords.value;
      },
      warnPageClose() {
        return vuex.hasChangesInDraftRecords.value;
      },
    });

    const { view } = useView();
    const { data: fetchData, status: fetchStatus } = await useLazyAsyncData(async () => {
      const route = useRoute();
      const { $localePath, $store } = useNuxtApp();
      const router = useRouter();

      const allPublicViewIds = $store.getters['workspace/allPublicViewIds'];

      // Check if the current viewId belongs to a public page
      if (allPublicViewIds.includes(route.params.viewId)) {
        return router.replace($localePath({ name: ROUTES.public.view.routeName, params: { tenantSlug: route.params.tenantSlug } }));
      }

      const { viewLoadError, columns } = await useViewFetch({ autoRecordRedirect: true });

      if (viewLoadError) {
        router.push($localePath({ name: ROUTES.member.views.routeName, params: { tenantSlug: route.params.tenantSlug } }));
        return { viewLoadError };
      }

      $store.commit('viewsMenu/resetFilterToolbarSearch');
      $store.commit('table/initTable');

      useTrackAppUsage('index');

      return {
        columns,
        viewLoadError,
      };
    }, {
      default() {
        return {
          columns: [],
          viewLoadError: null,
        };
      },
    });

    return {
      fetchData,
      fetchStatus,
      view,
      ...vuex,
    };
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    // eslint-disable-next-line vue/no-unused-properties
    viewChannelMixin__view() {
      return this.view;
    },
    pending() {
      return this.fetchStatus === 'pending';
    },
  },
});
</script>
