const PER_PAGE = 10;

export const state = () => ({
  isOpen: false,
  extraBottomSpace: false,
  hasPreviousMessages: true,
  currentPage: 1,
  messages: [],
});

export const getters = {};

export const mutations = {
  assign(state, payload) {
    Object.assign(state, payload);
  },
  toggle(state) {
    state.isOpen = !state.isOpen;
  },
  setExtraBottomSpace(state, value) {
    state.extraBottomSpace = value;
  },
  addMessage(state, message) {
    state.messages = [...state.messages, message];
  },
  setMessages(state, messages) {
    state.messages = messages;
  },
};

export const actions = {
  async getPreviousMessages({ state, commit }) {
    const { $apiClient } = useNuxtApp();
    const nextPage = state.currentPage + 1;

    const response = await $apiClient.ai.chat.messages.list({
      page: nextPage,
    });

    commit('assign', {
      messages: [...response, ...state.messages],
      currentPage: nextPage,
      hasPreviousMessages: response.length === PER_PAGE,
    });
  },
  async getMessages({ commit }) {
    const { $apiClient } = useNuxtApp();
    const response = await $apiClient.ai.chat.messages.list();

    commit('assign', {
      messages: response,
      currentPage: 1,
      hasPreviousMessages: response.length === PER_PAGE,
    });
  },
  async sendMessage({ state, commit }, content: string) {
    const { $apiClient } = useNuxtApp();

    commit('addMessage', {
      content,
      role: 'user',
    });

    try {
      const response = await $apiClient.ai.chat.messages.create({ content });

      // the api will return an array of messages, including the one we just sent
      commit('setMessages', [
        ...state.messages.slice(0, state.messages.length - 1),
        ...response,
      ]);
    } catch (error) {
      // revert last message
      commit('setMessages', state.messages.slice(0, state.messages.length - 1));
      throw error;
    }
  },
};

export const namespaced = true;
