
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexy5UhCbt4miMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/index.vue?macro=true";
import { default as indexONJeN1bRrhMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/login/index.vue?macro=true";
import { default as indexGWMsqq72UrMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/magic-link/index.vue?macro=true";
import { default as not_45yet6UUZPHAh2bMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/not-yet.vue?macro=true";
import { default as indexEpAnCvKRB3Meta } from "/codebuild/output/src2884824440/src/zazos-web/pages/reset-password/index.vue?macro=true";
import { default as set_45workspacet4isNHtPggMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/set-workspace.vue?macro=true";
import { default as index9Mx5a0aOugMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/sign-up/index.vue?macro=true";
import { default as _91appName_931N1EEntj7dMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/store/apps/[appName].vue?macro=true";
import { default as indexwU8Yt4EBgkMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/store/index.vue?macro=true";
import { default as indexOVzbIkH1O1Meta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue?macro=true";
import { default as index9L9uiyJiHlMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/index.vue?macro=true";
import { default as indexTROAQPBvcTMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/install/-review/index.vue?macro=true";
import { default as _91app_93QKoy5TiGYnMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/install/[app].vue?macro=true";
import { default as indexo30XchTvIBMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/login/index.vue?macro=true";
import { default as indexxnLKIPQ4U1Meta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue?macro=true";
import { default as indexFxlZ4ip2RPMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue?macro=true";
import { default as newvrMnirJBUvMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue?macro=true";
import { default as account_45configurationyvQ7UHRZBxMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue?macro=true";
import { default as indexnbItaBYHWVMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m/index.vue?macro=true";
import { default as mc7EYEVBI33Meta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m.vue?macro=true";
import { default as indexx5mM4REcTGMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue?macro=true";
import { default as indexGrAG2BTxj5Meta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue?macro=true";
import { default as index4LzCUS93jzMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/p/index.vue?macro=true";
import { default as reset_45passwordoVcIzxUplWMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue?macro=true";
import { default as indexXIi0V7jYd3Meta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue?macro=true";
import { default as indexCtDHqaRkZ2Meta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue?macro=true";
import { default as historyjJ8exu95FAMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue?macro=true";
import { default as indexKgxebD2wSMMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue?macro=true";
import { default as indexunSyeGkrYYMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue?macro=true";
import { default as import7WdamoSmZbMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue?macro=true";
import { default as index0KTOON6OBaMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue?macro=true";
import { default as schemaN3aCOaUeOkMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue?macro=true";
import { default as indexFdkUgLo6rGMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue?macro=true";
import { default as index9ma7IGftmzMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue?macro=true";
import { default as _91id_930BIGaLTmbxMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue?macro=true";
import { default as indexEszvOeN2GBMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue?macro=true";
import { default as indexo7FdAYFnVRMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue?macro=true";
import { default as index02kl2GChwXMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue?macro=true";
import { default as indexCQHI8DTyt7Meta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue?macro=true";
import { default as indexl01qxzs6dpMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue?macro=true";
import { default as _91providerSlug_93xqChPjiUclMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[providerSlug].vue?macro=true";
import { default as indexjBOok5WjMnMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue?macro=true";
import { default as integrationsG6aZGnRxseMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue?macro=true";
import { default as indexBK9f3DOOVrMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue?macro=true";
import { default as indexxQtq7UOLS2Meta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue?macro=true";
import { default as indexu93U4QwSMdMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue?macro=true";
import { default as _91id_93bMfXXRfvECMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue?macro=true";
import { default as indexb31uJbnGpcMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue?macro=true";
import { default as newJRkAK7a51xMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue?macro=true";
import { default as user_45groupsrPSeqDpZNiMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue?macro=true";
import { default as user_45signupTxAyp0dId0Meta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-signup.vue?macro=true";
import { default as indexfhQxsLKJ3OMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue?macro=true";
import { default as settings3Ke0PRcCwpMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue?macro=true";
import { default as studioQfsCdsSPVmMeta } from "/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio.vue?macro=true";
export default [
  {
    name: "index___en",
    path: "/en",
    meta: indexy5UhCbt4miMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/",
    meta: indexy5UhCbt4miMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/login/index.vue")
  },
  {
    name: "login___pt",
    path: "/login",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/login/index.vue")
  },
  {
    name: "magic-link___en",
    path: "/en/magic-link",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/magic-link/index.vue")
  },
  {
    name: "magic-link___pt",
    path: "/magic-link",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/magic-link/index.vue")
  },
  {
    name: "not-yet___en",
    path: "/en/not-yet",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/not-yet.vue")
  },
  {
    name: "not-yet___pt",
    path: "/not-yet",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/not-yet.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___pt",
    path: "/reset-password",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/reset-password/index.vue")
  },
  {
    name: "set-workspace___en",
    path: "/en/set-workspace",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/set-workspace.vue")
  },
  {
    name: "set-workspace___pt",
    path: "/set-workspace",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/set-workspace.vue")
  },
  {
    name: "sign-up___en",
    path: "/en/sign-up",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/sign-up/index.vue")
  },
  {
    name: "sign-up___pt",
    path: "/sign-up",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/sign-up/index.vue")
  },
  {
    name: "store-apps-appName___en",
    path: "/en/store/apps/:appName()",
    meta: _91appName_931N1EEntj7dMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/store/apps/[appName].vue")
  },
  {
    name: "store-apps-appName___pt",
    path: "/store/apps/:appName()",
    meta: _91appName_931N1EEntj7dMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/store/apps/[appName].vue")
  },
  {
    name: "store___en",
    path: "/en/store",
    meta: indexwU8Yt4EBgkMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/store/index.vue")
  },
  {
    name: "store___pt",
    path: "/store",
    meta: indexwU8Yt4EBgkMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/store/index.vue")
  },
  {
    name: "t-tenantSlug-email-validation___en",
    path: "/en/t/:tenantSlug()/email-validation",
    meta: indexOVzbIkH1O1Meta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue")
  },
  {
    name: "t-tenantSlug-email-validation___pt",
    path: "/t/:tenantSlug()/email-validation",
    meta: indexOVzbIkH1O1Meta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue")
  },
  {
    name: "t-tenantSlug___en",
    path: "/en/t/:tenantSlug()",
    meta: index9L9uiyJiHlMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/index.vue")
  },
  {
    name: "t-tenantSlug___pt",
    path: "/t/:tenantSlug()",
    meta: index9L9uiyJiHlMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/index.vue")
  },
  {
    name: "t-tenantSlug-install--review___en",
    path: "/en/t/:tenantSlug()/install/-review",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/install/-review/index.vue")
  },
  {
    name: "t-tenantSlug-install--review___pt",
    path: "/t/:tenantSlug()/install/-review",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/install/-review/index.vue")
  },
  {
    name: "t-tenantSlug-install-app___en",
    path: "/en/t/:tenantSlug()/install/:app()",
    meta: _91app_93QKoy5TiGYnMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/install/[app].vue")
  },
  {
    name: "t-tenantSlug-install-app___pt",
    path: "/t/:tenantSlug()/install/:app()",
    meta: _91app_93QKoy5TiGYnMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/install/[app].vue")
  },
  {
    name: "t-tenantSlug-login___en",
    path: "/en/t/:tenantSlug()/login",
    meta: indexo30XchTvIBMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/login/index.vue")
  },
  {
    name: "t-tenantSlug-login___pt",
    path: "/t/:tenantSlug()/login",
    meta: indexo30XchTvIBMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/login/index.vue")
  },
  {
    name: mc7EYEVBI33Meta?.name,
    path: "/en/t/:tenantSlug()/m",
    meta: mc7EYEVBI33Meta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m.vue"),
    children: [
  {
    name: "t-tenantSlug-m-viewId___en",
    path: ":viewId()",
    meta: indexxnLKIPQ4U1Meta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-recordId___en",
    path: ":viewId()/r/:recordId()",
    meta: indexFxlZ4ip2RPMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-new___en",
    path: ":viewId()/r/new",
    meta: newvrMnirJBUvMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue")
  },
  {
    name: "t-tenantSlug-m-account-configuration___en",
    path: "account-configuration",
    meta: account_45configurationyvQ7UHRZBxMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue")
  },
  {
    name: "t-tenantSlug-m___en",
    path: "",
    meta: indexnbItaBYHWVMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m/index.vue")
  }
]
  },
  {
    name: mc7EYEVBI33Meta?.name,
    path: "/t/:tenantSlug()/m",
    meta: mc7EYEVBI33Meta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m.vue"),
    children: [
  {
    name: "t-tenantSlug-m-viewId___pt",
    path: ":viewId()",
    meta: indexxnLKIPQ4U1Meta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-recordId___pt",
    path: ":viewId()/r/:recordId()",
    meta: indexFxlZ4ip2RPMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-new___pt",
    path: ":viewId()/r/new",
    meta: newvrMnirJBUvMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue")
  },
  {
    name: "t-tenantSlug-m-account-configuration___pt",
    path: "account-configuration",
    meta: account_45configurationyvQ7UHRZBxMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue")
  },
  {
    name: "t-tenantSlug-m___pt",
    path: "",
    meta: indexnbItaBYHWVMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/m/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-p-viewId___en",
    path: "/en/t/:tenantSlug()/p/:viewId()",
    meta: indexx5mM4REcTGMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId___pt",
    path: "/t/:tenantSlug()/p/:viewId()",
    meta: indexx5mM4REcTGMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId-r-recordId___en",
    path: "/en/t/:tenantSlug()/p/:viewId()/r/:recordId()",
    meta: indexGrAG2BTxj5Meta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId-r-recordId___pt",
    path: "/t/:tenantSlug()/p/:viewId()/r/:recordId()",
    meta: indexGrAG2BTxj5Meta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-p___en",
    path: "/en/t/:tenantSlug()/p",
    meta: index4LzCUS93jzMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/p/index.vue")
  },
  {
    name: "t-tenantSlug-p___pt",
    path: "/t/:tenantSlug()/p",
    meta: index4LzCUS93jzMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/p/index.vue")
  },
  {
    name: "t-tenantSlug-reset-password___en",
    path: "/en/t/:tenantSlug()/reset-password",
    meta: reset_45passwordoVcIzxUplWMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue")
  },
  {
    name: "t-tenantSlug-reset-password___pt",
    path: "/t/:tenantSlug()/reset-password",
    meta: reset_45passwordoVcIzxUplWMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue")
  },
  {
    name: "t-tenantSlug-sign-up___en",
    path: "/en/t/:tenantSlug()/sign-up",
    meta: indexXIi0V7jYd3Meta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue")
  },
  {
    name: "t-tenantSlug-sign-up___pt",
    path: "/t/:tenantSlug()/sign-up",
    meta: indexXIi0V7jYd3Meta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue")
  },
  {
    name: "t-tenantSlug-sso-saml___en",
    path: "/en/t/:tenantSlug()/sso/saml",
    meta: indexCtDHqaRkZ2Meta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue")
  },
  {
    name: "t-tenantSlug-sso-saml___pt",
    path: "/t/:tenantSlug()/sso/saml",
    meta: indexCtDHqaRkZ2Meta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue")
  },
  {
    name: studioQfsCdsSPVmMeta?.name,
    path: "/en/t/:tenantSlug()/studio",
    meta: studioQfsCdsSPVmMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-automations-id-history___en",
    path: "automations/:id()/history",
    meta: historyjJ8exu95FAMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue")
  },
  {
    name: "t-tenantSlug-studio-automations-id___en",
    path: "automations/:id()",
    meta: indexKgxebD2wSMMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-automations___en",
    path: "automations",
    meta: indexunSyeGkrYYMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-import___en",
    path: "data/:id()/import",
    meta: import7WdamoSmZbMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id___en",
    path: "data/:id()",
    meta: index0KTOON6OBaMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-schema___en",
    path: "data/:id()/schema",
    meta: schemaN3aCOaUeOkMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue")
  },
  {
    name: "t-tenantSlug-studio-data___en",
    path: "data",
    meta: indexFdkUgLo6rGMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue")
  },
  {
    name: "t-tenantSlug-studio___en",
    path: "",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue")
  },
  {
    name: "t-tenantSlug-studio-pages-id___en",
    path: "pages/:id()",
    meta: _91id_930BIGaLTmbxMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-pages___en",
    path: "pages",
    meta: indexEszvOeN2GBMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue")
  },
  {
    name: settings3Ke0PRcCwpMeta?.name,
    path: "settings",
    meta: settings3Ke0PRcCwpMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-app-builder___en",
    path: "app-builder",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-authentication___en",
    path: "authentication",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-global-parameters-manager___en",
    path: "global-parameters-manager",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings___en",
    path: "",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue")
  },
  {
    name: integrationsG6aZGnRxseMeta?.name,
    path: "integrations",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-integrations-providerSlug___en",
    path: ":providerSlug()",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[providerSlug].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-integrations___en",
    path: "",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-internal___en",
    path: "internal",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-system-entities___en",
    path: "system-entities",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-tokens___en",
    path: "tokens",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue")
  },
  {
    name: user_45groupsrPSeqDpZNiMeta?.name,
    path: "user-groups",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-user-groups-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups___en",
    path: "",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups-new___en",
    path: "new",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-user-signup___en",
    path: "user-signup",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-signup.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-users___en",
    path: "users",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue")
  }
]
  }
]
  },
  {
    name: studioQfsCdsSPVmMeta?.name,
    path: "/t/:tenantSlug()/studio",
    meta: studioQfsCdsSPVmMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-automations-id-history___pt",
    path: "automations/:id()/history",
    meta: historyjJ8exu95FAMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue")
  },
  {
    name: "t-tenantSlug-studio-automations-id___pt",
    path: "automations/:id()",
    meta: indexKgxebD2wSMMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-automations___pt",
    path: "automations",
    meta: indexunSyeGkrYYMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-import___pt",
    path: "data/:id()/import",
    meta: import7WdamoSmZbMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id___pt",
    path: "data/:id()",
    meta: index0KTOON6OBaMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-schema___pt",
    path: "data/:id()/schema",
    meta: schemaN3aCOaUeOkMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue")
  },
  {
    name: "t-tenantSlug-studio-data___pt",
    path: "data",
    meta: indexFdkUgLo6rGMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue")
  },
  {
    name: "t-tenantSlug-studio___pt",
    path: "",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue")
  },
  {
    name: "t-tenantSlug-studio-pages-id___pt",
    path: "pages/:id()",
    meta: _91id_930BIGaLTmbxMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-pages___pt",
    path: "pages",
    meta: indexEszvOeN2GBMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue")
  },
  {
    name: settings3Ke0PRcCwpMeta?.name,
    path: "settings",
    meta: settings3Ke0PRcCwpMeta || {},
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-app-builder___pt",
    path: "app-builder",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-authentication___pt",
    path: "authentication",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-global-parameters-manager___pt",
    path: "global-parameters-manager",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings___pt",
    path: "",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue")
  },
  {
    name: integrationsG6aZGnRxseMeta?.name,
    path: "integrations",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-integrations-providerSlug___pt",
    path: ":providerSlug()",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[providerSlug].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-integrations___pt",
    path: "",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-internal___pt",
    path: "internal",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-system-entities___pt",
    path: "system-entities",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-tokens___pt",
    path: "tokens",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue")
  },
  {
    name: user_45groupsrPSeqDpZNiMeta?.name,
    path: "user-groups",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-user-groups-id___pt",
    path: ":id()",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups___pt",
    path: "",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups-new___pt",
    path: "new",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-user-signup___pt",
    path: "user-signup",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-signup.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-users___pt",
    path: "users",
    component: () => import("/codebuild/output/src2884824440/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue")
  }
]
  }
]
  }
]